import React from 'react'

import styles from './ToggleRoute.module.scss'
import { ReactComponent as Dot } from './dot.svg'
import { ReactComponent as Lock } from './lock.svg'

const ToggleRoute = ({ iconColor, children, onToggle }) => (
  <label className={styles.container}>
    <span className={styles.content}>
      <Dot
        className={styles.checkmark}
        style={{
          fill: iconColor,
          borderColor: iconColor
        }}
      />
      <span className={styles.field}>{children}</span>
    </span>
    <span className={styles.indicator} onClick={onToggle}>
      <Lock />
    </span>
  </label>
)
export default ToggleRoute
