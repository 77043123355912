import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#222947',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  brandLogo: {
    position: 'absolute',
    top: '-30vw',
    right: '-30vw',
    zIndex: 1,
    opacity: 0.6
  },
  scroll: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    zIndex: 2
  },
  fill: {
    overflow: 'visible',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px'
  },
  title: {
    color: '#D8DFE9',
    fontSize: '2rem',
    fontWeight: 700,
    textAlign: 'left'
  }
}))
