import { Link as RouterLink } from 'react-router-dom'
import { Form as FormikForm } from 'formik'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import styledTheme from '../../../lib/theme'

export const useStyles = makeStyles(theme => ({
  icon: {
    color: '#D8DFE9'
  },
  input: {
    marginBottom: theme.spacing(1),
    '& label': {
      color: '#D8DFE9'
    },
    '& label.Mui-focused': {
      color: 'rgba(216, 223, 233, 0.61)'
    },
    '& .MuiInput-underline': {
      borderBottomColor: '#D8DFE9',
      '&:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#D8DFE9'
      },
      '&:after': {
        borderBottomColor: styledTheme.colors.modes.dark.green
      },
      '&:before': {
        borderBottomColor: '#D8DFE9'
      }
    },

    '& input.MuiInput-input': {
      color: styledTheme.colors.modes.dark.green
    },
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active': {
      '-webkit-transition':
        ' color 9999s ease-out, background-color 9999s ease-out',
      '-webkit-transition-delay': '9999s'
    }
  }
}))

export const Link = styled(RouterLink)`
  margin: 1rem 0;
  color: ${({ theme }) => theme.colors.green};
  border-bottom: 1px solid ${({ theme }) => theme.colors.green};
  align-self: flex-start;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Form = styled(FormikForm)`
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: stretch;
`
