import React from 'react'
import cx from 'classnames'
import difference_in_minutes from 'date-fns/difference_in_minutes'
import TimeAgo from '../../../components/TimeAgo'
import styles from './Row.module.scss'

const Row = ({ time, power, display_name, routeName, checkin }) => {
  const difference = difference_in_minutes(new Date(), time)

  return (
    <div className={styles.row}>
      <div className={styles.container}>
        <div className={styles.status}>
          <div
            className={cx(styles.circle, {
              [styles.red]: !checkin,
              [styles.yellow]: checkin && difference > 15,
              [styles.green]: checkin && difference < 10
            })}
          />
          <TimeAgo time={time} component="small" />
        </div>
        <div className={styles.battery}>
          <label>Battery</label> <br />
          <small>{Math.floor(power)}%</small>
        </div>
        <div className={styles.information}>
          <div className={styles.content}>
            <p>{display_name} </p>
            <p>{routeName}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Row
