import React from 'react'
import { Typography } from '@material-ui/core'
import { BrandText } from '../../components/BrandText'
import { BrandFullLogo } from '../../components/Logo'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './styled'
import LoginForm from '../../components/Forms/Login'

export default () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.brandLogo}>
        <BrandFullLogo />
      </div>
      <div className={classes.scroll}>
        <BrandText />

        <div className={classes.fill}>
          <div className={classes.loginContainer}>
            <Typography className={classes.title} color="textPrimary">
              <FormattedMessage id="text.login.title" />
            </Typography>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  )
}
