import { getQueryParam } from '../components/QueryParamsProvider'

const REQUEST_TYPES = {
  KNOMATIC: 'knomatic_action',
  EXTERNAL: 'external_call',
  KNOMATIC_RDS: 'knomatic_rds'
}
export const PAYLOAD_TYPE = {
  QUERY: 'query',
  PAYLOAD: 'payload'
}

const payloadBuilder = (actionPayload, markerPayload, queryParams) => {
  const dict = {}
  markerPayload.forEach(mp => (dict[mp.key] = mp.value))

  const queryPayload = getQueryParam('payload', queryParams)

  const data = {}
  actionPayload.forEach(p => {
    if (p.type === PAYLOAD_TYPE.QUERY) {
      if (!!queryPayload[p.key]) {
        data[p.key] = queryPayload[p.key]
      } else {
        data[p.key] = p.default
      }
    } else if (p.type === PAYLOAD_TYPE.PAYLOAD) {
      if (dict[p.key] !== undefined && dict[p.key] !== null) {
        data[p.key] = dict[p.key]
      } else {
        data[p.key] = p.default
      }
    }
  })

  return data
}

export const actionBuilder = (action, payload, queryParams = null) => {
  const { request = {}, payload: actionPayload, uuid } = action

  let {
    rdsName = false,
    rdsOperation = false,
    solutionUuid,
    solutionEnvironment,
    type,
    baseURL = false
  } = request

  let data = payloadBuilder(actionPayload, payload, queryParams)
  let path

  switch (type) {
    case REQUEST_TYPES.KNOMATIC:
      baseURL = baseURL ? baseURL : process.env.REACT_APP_API_ENDPOINT
      path = `${baseURL}/flow/solution/${solutionUuid}/actions/${uuid}`
      return {
        path,
        payload: {
          solutionEnvironment,
          data,
          source: 'payload'
        }
      }

    case REQUEST_TYPES.KNOMATIC_RDS:
      if (!rdsName || !rdsOperation) {
        throw new Error(
          `rdsName and rdsOperation are required for RDS action sent: ${rdsName} ${rdsOperation} `
        )
      }
      baseURL = baseURL ? baseURL : process.env.REACT_APP_RDS_API_ENDPOINT
      path = `${baseURL}/datasets/${rdsName}/operation`
      return {
        path,
        payload: {
          operation: rdsOperation,
          parameters: data
        }
      }
    default:
      path = `${request.baseURL}${request.endpoint}`
      if (request.params) {
        const paramsArray = path.match(/{([^}]+)}/g)
        paramsArray.forEach(
          p => (path = path.replace(p, data[p.substring(1, p.length - 1)]))
        )
      }
      return {
        path,
        payload: data,
        operation: request.operation
      }
  }
}

export const parseTexts = (text, queryParams, payload) => {
  const dynamicValues = text.match(/{{([^}}]+)}}/g) || []
  dynamicValues.forEach(v => {
    const [type, key] = v.substring(2, v.length - 2).split('_')

    if (type === PAYLOAD_TYPE.QUERY) {
      const queryPayload = getQueryParam('payload', queryParams)

      if (!!queryPayload[key]) {
        text = text.replace(v, queryPayload[key])
      }
    } else if (type === PAYLOAD_TYPE.PAYLOAD) {
      const payloadObj = {}
      payload.forEach(mp => (payloadObj[mp.key] = mp.value))

      if (!!payloadObj[key]) {
        text = text.replace(v, payloadObj[key])
      }
    }
  })

  return text
}
