import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { RecoilRoot, useRecoilState } from 'recoil'
import { ThemeProvider } from 'styled-components'
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider as MaterialThemeProvider
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { appTheme, appLanguage } from '../../states/globalConfig/states'
import { messages } from '../../lib/i18n'
import theme from '../../lib/theme'
import { StyledApp, GlobalStyles } from '../../lib/theme/StyledApp'

import AuthProvider from '../../components/AuthProvider'
import MarkerProvider from '../../components/MarkerProvider'
import RoutesProvider from '../../components/RoutesProvider'
import EventsProvider from '../../components/EventsProvider'
import LocationsProvider from '../../components/LocationsProvider'
import { ConfirmationDialogProvider } from '../../components/ConfirmationDialog'
import { QueryParamsProvider } from '../../components/QueryParamsProvider'
import { AlertProvider } from '../../components/Alert'
import ViewsProvider from '../../components/ViewsProvider'
import PublicRoute from '../../components/PublicRoute'
import PrivateRoute from '../../components/PrivateRoute'
import { composeProviders } from '../../utils'
import Login from '../Login'
import BetaTracker from '../BetaTracker'
import Management from '../Management'

const Provider = composeProviders(
  AuthProvider,
  MarkerProvider,
  RoutesProvider,
  LocationsProvider,
  ConfirmationDialogProvider,
  QueryParamsProvider,
  AlertProvider,
  EventsProvider,
  ViewsProvider
)

const App = () => {
  let MapRoute = PrivateRoute
  if (
    process.env.REACT_APP_PUBLIC_TRACKER &&
    process.env.REACT_APP_PUBLIC_TRACKER === 'true'
  ) {
    MapRoute = PublicRoute
  }

  const [themeState] = useRecoilState(appTheme)
  const [languageState] = useRecoilState(appLanguage)

  const muiThemeConfig = {
    palette: {
      type: themeState,
      primary: {
        light: theme.colors.modes.light.blue,
        dark: theme.colors.modes.dark.blue,
        contrastText: '#fefefe',
        main: theme.colors.modes[themeState].blue
      },
      secondary: {
        light: theme.colors.modes.light.red,
        dark: theme.colors.modes.dark.red,
        contrastText: '#fefefe',
        main: theme.colors.modes[themeState].red
      },
      error: {
        light: theme.colors.modes.light.red,
        dark: theme.colors.modes.dark.red,
        contrastText: '#fefefe',
        main: theme.colors.modes[themeState].red
      },
      hoverEffect: {
        contrastText: '#000',
        light: '#000',
        dark: '#000',
        main: theme.colors.modes[themeState].inputHover
      }
    }
  }

  const materialTheme = createMuiTheme(muiThemeConfig)
  const themeConfig = { colors: theme.colors.modes[themeState] }

  return (
    <IntlProvider
      messages={messages[languageState]}
      locale={languageState}
      defaultLocale="en"
    >
      <MaterialThemeProvider theme={materialTheme}>
        <ThemeProvider theme={themeConfig}>
          <StyledApp />
          <CssBaseline />
          <GlobalStyles>
            <BrowserRouter>
              <Provider>
                <Switch>
                  <PublicRoute
                    exact
                    path="/"
                    component={Login}
                    forceRedirect
                    layout={false}
                  />
                  <MapRoute
                    path="/tracker/:viewSlug?"
                    component={BetaTracker}
                  />
                  <PrivateRoute path="/management" component={Management} />
                </Switch>
              </Provider>
            </BrowserRouter>
          </GlobalStyles>
        </ThemeProvider>
      </MaterialThemeProvider>
    </IntlProvider>
  )
}

const Wrapper = () => {
  return (
    <RecoilRoot>
      <App />
    </RecoilRoot>
  )
}

export default Wrapper
