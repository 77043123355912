import axios from 'axios'
import { API_instance, RDS_instance } from './instance'

export const authenticate = payload =>
  axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/sessions`, payload)
    .then(response => response.data)

export const validate = token =>
  API_instance()
    .post(`${process.env.REACT_APP_API_ENDPOINT}/auth/validateSession`, {
      token
    })
    .then(response => response.data)

export const rdsApiRequest = (operation, payload) =>
  RDS_instance()
    .post(`/datasets/GETZ/operation`, {
      operation,
      parameters: payload
    })
    .then(response => response.data)
