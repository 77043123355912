import styled from 'styled-components'
import { color, space } from 'styled-system'

export const Rounded = styled.button`
  border: none;
  outline: none;
  background: none;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  height: 3rem;
  border-radius: 1.5rem;
  margin: 0.5rem;
  font-weight: 700;
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  ${color};
  ${space};
`
