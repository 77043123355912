import React, { useState, useContext } from 'react'

import './Panel.scss'
import cx from 'classnames'
import difference_in_minutes from 'date-fns/difference_in_minutes'
import TimeAgo from '../../../components/TimeAgo'
import { Button, Menu, MenuItem, withStyles } from '@material-ui/core'
import { ConfirmationDialogContext } from '../../../components/ConfirmationDialog'
import { AlertContext } from '../../../components/Alert'
import { actionRequest } from '../../../services/markerActions'
import {
  getQueryParam,
  QueryParamsContext
} from '../../../components/QueryParamsProvider'
import { parseTexts } from '../../../utils'
import { PanelRow } from './PanelRow'
import { Icon } from '../Marker/icons/Icon'
import { getCallback } from './actionsUtils'
import { withRouter } from 'react-router-dom'
import { TagsMarkersContext } from '../../../components/TagsMarkersProvider'

const BiggerMenuItem = withStyles({
  root: {
    minWidth: '300px',
    justifyContent: 'center'
  }
})(MenuItem)

const Panel = props => {
  let {
    icon_type,
    color,
    display_name,
    power,
    closePanel,
    check_in_status,
    checkin,
    time,
    display = [],
    actions = [],
    payload
  } = props
  const [anchorEl, setAnchorEl] = useState()
  const { setState } = useContext(ConfirmationDialogContext)
  const { ResetMarkers } = useContext(TagsMarkersContext)
  const { setAlertState } = useContext(AlertContext)
  const { queryParams = '', setQueryParams } = useContext(QueryParamsContext)
  const qEnableActs = getQueryParam('enableActions', queryParams, false) || ''
  const qCallbackUrl = getQueryParam('callbackUrl', queryParams, false) || ''
  const showActions =
    (process.env.REACT_APP_DISABLE_ACTIONS_BY_DEFAULT !== 'true' ||
      qEnableActs === 'true') &&
    actions.length > 0

  let headerColor = color ? color : '#403f3f'
  if (check_in_status === 'On Break') {
    headerColor = '#426267'
  }

  const difference = difference_in_minutes(new Date(), time)

  const handleClose = () => setAnchorEl(null)

  const callbackExtras = {
    props,
    ResetMarkers,
    setQueryParams
  }
  const handleClick = action => () => {
    const request = () =>
      actionRequest(action, payload, queryParams)
        .then(() => {
          setAlertState({
            show: true,
            type: 'success',
            message: parseTexts(action.successMessage, queryParams, payload)
          })
          if (action.callback) {
            getCallback(action.callback.type)(callbackExtras)
          }
          closePanel()
          if (qCallbackUrl) {
            window.location.href = decodeURIComponent(qCallbackUrl)
          }
        })
        .catch(() => {
          setAlertState({
            show: true,
            type: 'error',
            message: parseTexts(action.errorMessage, queryParams, payload)
          })
          if (action.callback) {
            getCallback(action.callback.type)(callbackExtras)
          }
        })

    if (action.confirmation) {
      let {
        cancel = '',
        confirm = '',
        message = ''
      } = action.confirmationConfig

      setState({
        show: true,
        message: parseTexts(message, queryParams, payload),
        confirm,
        cancel,
        confirmAction: request
      })
      handleClose()
      return
    }

    request()
    handleClose()
  }

  return (
    <div className="card">
      <div className="card__header" style={{ backgroundColor: headerColor }}>
        <div className=" bus_logo">
          <Icon name={icon_type} fill={color} />
        </div>
        <div className="card__header__section">
          <div className="headsign">{display_name || 'No Desc'}</div>
          <div className="battery-status">
            <div
              className={cx('circle', {
                red: !checkin,
                yellow: checkin && difference > 15,
                green: checkin && difference < 10
              })}
            />
            <TimeAgo time={time} component="small" />
          </div>
          {!!power && (
            <div className="battery-level">
              <label>Battery </label>
              <small>{Math.floor(power)}%</small>
            </div>
          )}
        </div>
        <div className="close" onClick={closePanel}>
          X
        </div>
      </div>
      <div className="card__body">
        {display.map((elem, i) => (
          <PanelRow key={`${elem.key}-${i}`} {...elem} />
        ))}
      </div>
      {showActions ? (
        <div className="card__footer" style={{ backgroundColor: color }}>
          <Button
            aria-controls="actions-menu"
            aria-haspopup="true"
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            {process.env.REACT_APP_DISABLE_ACTIONS_MENU_LABEL || 'Actions'}
          </Button>
          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            getContentAnchorEl={null}
          >
            {actions.map((action, i) => (
              <BiggerMenuItem
                key={`${i}-${action.label}`}
                onClick={handleClick(action)}
              >
                {action.label}
              </BiggerMenuItem>
            ))}
          </Menu>
        </div>
      ) : null}
    </div>
  )
}

export default withRouter(Panel)
