import axios from 'axios'
import { actionBuilder } from '../utils'

export const actionRequest = (action, payload, routeLocation = null) => {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  const request = actionBuilder(action, payload, routeLocation)

  if (!!request.operation) {
    if (request.operation === 'GET') {
      return axios.get(request.path)
    }
    return axios[request.operation.toLowerCase()](request.path, request.payload)
  }

  return axios.post(request.path, request.payload, config)
}
