import React from 'react'
import cn from 'classnames'

import { withRoutesContext } from '../../../../components/RoutesProvider'
import MarkerList from '../MarkerList'
import RouteField from '../ToggleRoute'
import styles from './RouteList.module.scss'

const RouteList = ({ routes, filter, onToggleRoute, onToggleMarker }) => (
  <ul className={styles.inner}>
    {routes.map(route => (
      <li
        key={route.id}
        className={cn(styles.inner, {
          [styles.inner_filter]:
            filter &&
            (route.id === filter || route.name.toLowerCase().includes(filter))
        })}
      >
        <RouteField iconColor={route.color} onToggle={onToggleRoute}>
          <b>{route.id}</b> <span>{route.name}</span>
        </RouteField>

        <MarkerList route={route} filter={filter} onToggle={onToggleMarker} />
      </li>
    ))}
  </ul>
)

const mapData = ({ routes }, { routes: routeIds, filter }) => {
  return {
    routes: routeIds
      .map(id => routes[id])
      .sort(a => {
        if (!filter) return 0

        if (a.id === filter || a.name.toLowerCase().includes(filter)) {
          return -1
        }

        return 1
      })
  }
}

export default withRoutesContext(mapData)(RouteList)
