import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/VisibilityOutlined'
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined'
import { AuthContext } from '../../AuthProvider'
import { Rounded } from '../../UI/Buttons'
import { useStyles, Container, Form, Link } from './styled'

const SignInSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'form.message.short')
    .max(50, 'form.message.long')
    .required('form.message.required'),
  email: Yup.string()
    .min(3, 'form.message.short')
    .max(50, 'form.message.long')
    .email('form.message.invalid_email')
    .required('form.message.required')
})

const Login = () => {
  const classes = useStyles()
  const initialValues = useRef({
    email: '',
    password: ''
  })

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(val => !val)

  const handleMouseDownPassword = event => event.preventDefault()

  const passControl = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? (
          <Visibility className={classes.icon} />
        ) : (
          <VisibilityOff className={classes.icon} />
        )}
      </IconButton>
    </InputAdornment>
  )

  return (
    <Container>
      <AuthContext.Consumer>
        {({ login: handleLogin }) => (
          <Formik
            initialValues={initialValues.current}
            validationSchema={SignInSchema}
            onSubmit={handleLogin}
          >
            {({ errors, touched, handleChange, values }) => (
              <Form autoComplete="off">
                <TextField
                  className={classes.input}
                  label="Username or email"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  error={Boolean(errors.email && touched.email)}
                  helperText={
                    errors.email && touched.email ? (
                      <FormattedMessage
                        id={errors.email}
                        values={{ max: 50, min: 5 }}
                      />
                    ) : (
                      ''
                    )
                  }
                />

                <TextField
                  className={classes.input}
                  label={<FormattedMessage id="input.password" />}
                  value={values.password}
                  name="password"
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  error={Boolean(errors.password && touched.password)}
                  helperText={
                    errors.password && touched.password ? (
                      <FormattedMessage
                        id={errors.password}
                        values={{ max: 50, min: 3 }}
                      />
                    ) : (
                      ''
                    )
                  }
                  InputProps={{
                    endAdornment: passControl
                  }}
                />

                <Link to="/">
                  <FormattedMessage id="form.login.button.forgot_password.label" />
                </Link>

                <Rounded
                  bg="green"
                  type="submit"
                  color="deepSea"
                  m={0}
                  mt="2rem"
                >
                  <FormattedMessage id="button.login" />
                </Rounded>
              </Form>
            )}
          </Formik>
        )}
      </AuthContext.Consumer>
    </Container>
  )
}

export default Login
