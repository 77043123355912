import React from 'react'
import { withRouter } from 'react-router-dom'

import { compose } from '../../utils'
import { withAuthContext } from '../AuthProvider'
import Header from './Header'
import styles from './Layout.module.scss'

const Layout = ({ children, ...props }) => (
  <div className={styles.layout}>
    <Header {...props} />
    <div className={styles.content}>{children}</div>
  </div>
)

export default compose(
  withRouter,
  withAuthContext
)(Layout)
