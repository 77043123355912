import React from 'react'
import cn from 'classnames'

import styles from './ToggleButtons.module.scss'

const ToggleButtons = ({ name, values, selected, onToggle }) => (
  <div className={styles.container}>
    {values.map(({ value, children }) => (
      <label
        className={cn(styles.toggle, {
          [styles.toggle_selected]: value === selected
        })}
        key={value}
      >
        <input
          type="radio"
          name={name}
          value={value}
          checked={value === selected}
          onChange={onToggle}
        />
        <span>{children}</span>
      </label>
    ))}
  </div>
)

export default ToggleButtons
