const colors = {
  modes: {
    light: {
      text: '#303030',
      bg: '#fefefe',
      default: '#f2f2f7',
      deepSea: '#262A40',
      blue: '#007bff',
      green: '#36D99D',
      indigo: '#5856d6',
      orange: '#ff9500',
      pink: '#ff2d55',
      purple: '#af52de',
      red: '#FF7658',
      teal: '#5ac7fa',
      yellow: '#ffcc00',
      inputHover: 'rgba(0,0,0,0.05)',

      gray: ['#8e8e93', '#aeaeb2', '#c7c7cc', '#d1d1d6', '#e5e5ea', '#f2f2f7']
    },
    dark: {
      text: '#fefefe',
      bg: '#303030',
      default: '#000',
      deepSea: '#262A40',
      blue: '#0a84ff',
      green: '#36D99D',
      indigo: '#5e5ce6',
      orange: '#ff9f0a',
      pink: '#ff375f',
      purple: '#bf5af2',
      red: '#FF7658',
      teal: '#64d3ff',
      yellow: '#ffd60a',
      inputHover: 'rgba(255,255,255,0.05)',
      gray: ['#8e8e93', '#636366', '#48484a', '#3a3a3c', '#2c2c2e', '#1c1c1e']
    }
  }
}

const theme = {
  colors
}

export default theme
