import React, { PureComponent } from 'react'
import cn from 'classnames'

import { withEventsContext } from '../../../components/EventsProvider'
import { withRoutesContext } from '../../../components/RoutesProvider'
import { compose } from '../../../utils'
import RouteList from './RouteList'
import ToggleButtons from './ToggleButtons'
import { ReactComponent as More } from './more.svg'

import styles from './SideMenu.module.scss'

class SideMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      filter: '',
      mode: 'all'
    }

    this.handleChange = this.handleChange.bind(this)
    this.onToggleMode = this.onToggleMode.bind(this)
    this.closePanel = this.closePanel.bind(this)
    this.openPanel = this.openPanel.bind(this)
  }

  handleChange({ target }) {
    this.setState({
      filter: target.value
    })
    // const { onFilterRoute } = this.props
    // this.setState(state, () => {
    //   onFilterRoute({
    //     visibleRoutes: this.state.selectedRoutes,
    //     visibleMarkers: this.state.selectedMarkers
    //   })
    // })
  }

  onToggleMode({ target }) {
    this.setState({
      mode: target.value
    })
  }

  closePanel() {
    this.setState({
      isOpen: false
    })
  }

  openPanel() {
    this.setState({
      isOpen: true
    })
  }

  renderEvents() {
    const { events, routesByEvent } = this.props
    const { filter } = this.state

    const filterParsed = filter.toLowerCase()

    return events
      .sort((a, b) => {
        if (!filter) return 0

        if (a.eventname.toLowerCase().includes(filterParsed)) {
          return 1
        }
        if (b.eventname.toLowerCase().includes(filterParsed)) {
          return 1
        }

        return 0
      })
      .map(event => (
        <li
          key={event.eventid}
          className={cn(styles.event, {
            [styles.event_filter]:
              filter && event.eventname.toLowerCase().includes(filterParsed)
          })}
        >
          <span className={styles.field}>
            <b>{event.eventname}</b>
          </span>
          {routesByEvent && routesByEvent[event.eventhash] && (
            <RouteList
              filter={filterParsed}
              routes={routesByEvent[event.eventhash]['routes']}
              onToggleRoute={() => console.log('LOG: Route')}
              onToggleMarker={() => console.log('LOG: Marker')}
            />
          )}
        </li>
      ))
  }

  render() {
    const { isOpen, filter, mode } = this.state

    if (!isOpen) {
      return (
        <button
          type="button"
          className={`${styles.button} ${styles.button_close}`}
          onClick={this.openPanel}
        >
          <More />
        </button>
      )
    }

    return (
      <div className={styles.container}>
        <button
          type="button"
          className={`${styles.button} ${styles.button_open}`}
          onClick={this.closePanel}
        >
          {'<<'}
        </button>
        <div className={styles.content}>
          <input
            type="text"
            value={filter}
            onChange={this.handleChange}
            placeholder="Event, route or marker..."
          />

          <ToggleButtons
            name="filter"
            values={[
              { value: 'all', children: 'all' },
              { value: 'visible', children: 'visible' }
            ]}
            selected={mode}
            onToggle={this.onToggleMode}
          />

          <ul className={styles.events}>{this.renderEvents()}</ul>
        </div>
      </div>
    )
  }
}

const mapData = ({ routesByEvent }) => ({ routesByEvent })

export default compose(
  withEventsContext,
  withRoutesContext(mapData)
)(SideMenu)
