import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { withAuthContext } from '../AuthProvider'
import Layout from '../Layout'

const PublicRoute = ({
  component: Component,
  authorizedUser,
  forceRedirect,
  forceTo = '/tracker',
  location,
  layout = true,
  ...rest
}) => {
  if (forceRedirect && authorizedUser) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: forceTo
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={matchProps => {
        if (layout) {
          return (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          )
        }
        return <Component {...matchProps} />
      }}
    />
  )
}

export default withAuthContext(PublicRoute)
