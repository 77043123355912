import React from 'react'

import { ReactComponent as Mark } from './location.svg'
import { mark, markerLabel } from './LocationMarker.module.scss'

const LocationMarker = ({ maplabel, locationcolor }) => {
  let color = locationcolor || '#403f3f'
  return (
    <div className={mark}>
      <div className={markerLabel}>
        <span>{maplabel}</span>
      </div>
      <Mark fill={color} />
    </div>
  )
}

export default LocationMarker
