import React, { PureComponent } from 'react'

import base from '../../lib/firebase'

export const withEventsContext = WrappedComponent => props => (
  <EventsContext.Consumer>
    {state => <WrappedComponent {...props} {...state} />}
  </EventsContext.Consumer>
)

export const EventsContext = React.createContext([])

export default class EventsProvider extends PureComponent {
  state = {
    events: [
      {
        eventhash: '3de64950-0906-486b-8fd0-2c02bfaf5cf3',
        eventid: 7,
        slug: 'event7',
        eventname: 'HOUSTON LIVESTOCK SHOW & RODEO'
      }
    ]
  }

  componentDidMount() {
    base.bindToState('events', {
      context: this,
      asArray: true,
      state: 'events'
    })
  }

  render() {
    const { events } = this.state

    return (
      <EventsContext.Provider value={{ events }}>
        {this.props.children}
      </EventsContext.Provider>
    )
  }
}
