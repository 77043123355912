import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row } from '../../UI/Contents'
import { BrandLogo } from '../../Logo'
import {
  HeaderContainer,
  Title,
  ProfileBtn,
  NavBar,
  NavItem,
  Menu,
  MenuItem
} from './styled'

const Header = props => {
  const [isOpen, setIsOpen] = useState(false)
  const { authorizedUser, logout } = props

  let profile
  if (authorizedUser) {
    const { firstName, lastName } = authorizedUser
    profile = (
      <>
        <ProfileBtn
          className={isOpen ? 'focus' : ''}
          $open={isOpen}
          $color={process.env.REACT_APP_HEADER_ACTIVE_ITEM_COLOR}
          onClick={() => setIsOpen(s => !s)}
        >
          <span>
            {firstName} {lastName}
          </span>
          <span className="caret" />
        </ProfileBtn>
        {isOpen && (
          <Menu role="menu">
            <li role="presentation">
              <MenuItem role="menuitem" onClick={() => logout()}>
                Logout
              </MenuItem>
            </li>
          </Menu>
        )}
      </>
    )
  }

  const logoSrc =
    process.env.REACT_APP_HEADER_LOGO_URL ||
    'https://dev-tfm-studio-s3.s3.amazonaws.com/tfm_base_logo_7dd87ac54e.png'

  const HeaderBrand = (
    <Link to="/tracker">
      <BrandLogo src={logoSrc} alt="Brand logo" />
    </Link>
  )

  return (
    <HeaderContainer
      $mainBg={process.env.REACT_APP_HEADER_MAIN_BG_COLOR}
      $textColor={process.env.REACT_APP_HEADER_TEXT_COLOR}
    >
      <Row alignItems="center">{HeaderBrand}</Row>

      {process.env.REACT_APP_HEADER_MID_TEXT && (
        <Title>{process.env.REACT_APP_HEADER_MID_TEXT}</Title>
      )}

      <NavBar>
        <ul>
          <li>
            <NavItem
              to="/management"
              activeClassName="active"
              $color={process.env.REACT_APP_HEADER_ACTIVE_ITEM_COLOR}
            >
              Management
            </NavItem>
          </li>
          <li>
            <NavItem
              to="/tracker"
              activeClassName="active"
              $color={process.env.REACT_APP_HEADER_ACTIVE_ITEM_COLOR}
            >
              Tracker
            </NavItem>
          </li>
          <li>{profile}</li>
        </ul>
      </NavBar>
    </HeaderContainer>
  )
}

export default Header
