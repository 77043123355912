import React from 'react'

import styles from './Loading.module.scss'

const Loading = () => (
  <div className={styles.loading}>
    <span className={styles.message}>LOADING...</span>
  </div>
)

export default Loading
