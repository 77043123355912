import React, { PureComponent } from 'react'
import { normalize, schema } from 'normalizr'

import firebase from '../../lib/firebase'

const routeSchema = new schema.Entity('routes', {}, { idAttribute: 'routeid' })

export const RoutesContext = React.createContext()

export const withRoutesContext = mapData => WrappedComponent => props => (
  <RoutesContext.Consumer>
    {({ onFilterRoute, ...state }) => (
      <WrappedComponent
        {...props}
        {...mapData(state, props)}
        onFilterRoute={onFilterRoute}
      />
    )}
  </RoutesContext.Consumer>
)

class RoutesProvider extends PureComponent {
  state = {
    routes: {},
    events: {},
    visibleRoutes: []
  }

  onFilterRoute = ({ visibleRoutes }) => {
    this.setState({ visibleRoutes })
  }

  componentDidMount() {
    firebase.listenTo('routes', {
      context: this,
      then: data => {
        const { entities } = normalize(data, [routeSchema])

        this.setState({
          ...entities,
          visibleRoutes:
            !!entities && !!entities.routes ? Object.keys(entities.routes) : []
        })
      }
    })
  }

  render() {
    const { routes, events, visibleRoutes } = this.state

    return (
      <RoutesContext.Provider
        value={{
          routes,
          routesByEvent: events,
          visibleRoutes,
          onFilterRoute: this.onFilterRoute
        }}
      >
        {this.props.children}
      </RoutesContext.Provider>
    )
  }
}

export default RoutesProvider
