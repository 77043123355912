import styled from 'styled-components'
import {
  flexbox,
  layout,
  color,
  space,
  position,
  bottom,
  borders
} from 'styled-system'

export const Row = styled('div')`
  ${flexbox}
  ${layout}
  ${color}
  ${space}
  ${position}
  ${bottom}
  ${borders}
  display: flex;
  flex-direction: row;
`
