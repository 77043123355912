import React, { createContext, useEffect, useState } from 'react'

const IsInsideRadius = (checkPoint, centerPoint, km) => {
  var ky = 40000 / 360
  var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky
  var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx
  var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky
  return Math.sqrt(dx * dx + dy * dy) <= km
}

const ApplyRadiusFilter = (markers, center, zoom) => {
  let m = [...markers]
  const kmRadius = +process.env.REACT_APP_MARKERS_KM_RADIUS || 10
  const kmRadiusFactorPerPointOfZoom =
    +process.env.REACT_APP_MARKERS_KM_RADIUS_FACTOR_PER_ZOOM || 2
  const kmRadiusMaxZoomScale =
    +process.env.REACT_APP_MARKERS_KM_RADIUS_MAX_ZOOM_SCALE || 16

  const km =
    kmRadius * kmRadiusFactorPerPointOfZoom * (kmRadiusMaxZoomScale + 1 - zoom)
  m = m.filter(m => IsInsideRadius(m, center, km))
  m = m.slice(0, +process.env.REACT_APP_MARKERS_RENDER_LIMIT || 100)
  return m
}

const ApplyFilters = (filters, tags, markers) => {
  let filteredMarkers = [...markers]

  if (tags.length > 0) {
    filteredMarkers = filteredMarkers.filter(m => {
      if (!m.tags) {
        return false
      }

      const markers_tags = m.tags.map(t => t.key)
      return tags.some(t => markers_tags.includes(t))
    })
  }

  if (filters.length > 0) {
    const filtersModified = {}
    filters.forEach(f => {
      const keyXAttrXValue = f.split('_')
      const value = keyXAttrXValue.pop()
      const prop = keyXAttrXValue.pop()
      const filter = keyXAttrXValue.join('_')
      if (!Object.keys(filtersModified).includes(filter)) {
        const obj = {}
        obj[prop] = [value]
        filtersModified[filter] = obj
      } else if (
        !!Object.keys(filtersModified).includes(filter) &&
        !filtersModified[filter][prop]
      ) {
        filtersModified[filter][prop] = [value.toLowerCase()]
      } else {
        filtersModified[filter][prop] = [
          ...filtersModified[filter][prop],
          value.toLowerCase()
        ]
      }
    })

    filteredMarkers = filteredMarkers.filter(m => {
      const markers_tags = m.tags.map(t => t.key)
      let flags = []
      for (const t of markers_tags) {
        let flag = tags.includes(t)
        if (!!filtersModified[t]) {
          for (const [k, v] of Object.entries(filtersModified[t])) {
            if (flag && !v.includes(`${m[k]}`.toLowerCase())) {
              flag = false
            }
          }
        }
        flags.push(flag)
      }

      return flags.some(f => f)
    })
  }

  return filteredMarkers
}

export const TagsMarkersContext = createContext({
  state: {
    originalMarkers: [],
    markers: [],
    filters: [],
    tags: []
  },
  setState: () => {},
  ApplyFilters: () => {},
  IsInsideRadius: () => {},
  ApplyRadiusFilter: () => {},
  ResetMarkers: () => {}
})

export const withTagsMarkersContext = WrappedComponent => props => (
  <TagsMarkersContext.Consumer>
    {state => <WrappedComponent {...props} {...state} />}
  </TagsMarkersContext.Consumer>
)

export const TagsMarkersProvider = ({
  defaultCenter,
  center,
  zoom,
  markers = [],
  filters,
  tags,
  showLocator,
  showCenter,
  children
}) => {
  const originalMarkers = [...markers]

  let filteredMarkers = ApplyFilters(filters, tags, markers)
  filteredMarkers = ApplyRadiusFilter(filteredMarkers, center, zoom)

  const locatorMarker = {
    display: [],
    display_name: 'Locator',
    icon_type: 'black_marker',
    key: 'locator',
    lat: center.lat,
    lng: center.lng
  }
  const centerMarker = {
    display: [],
    display_name: 'Center',
    icon_type: 'black_marker',
    key: 'center',
    lat: defaultCenter.lat,
    lng: defaultCenter.lng
  }

  const [state, setState] = useState({
    originalMarkers,
    markers: filteredMarkers,
    filters,
    tags
  })

  useEffect(() => {
    let filteredMarkers = ApplyFilters(state.filters, state.tags, markers)
    filteredMarkers = ApplyRadiusFilter(filteredMarkers, center, zoom)

    if (showLocator) {
      filteredMarkers.push(locatorMarker)
    }
    if (showCenter) {
      filteredMarkers.push(centerMarker)
    }

    setState(s => ({
      ...s,
      originalMarkers: [...markers],
      markers: filteredMarkers
    }))
  }, [markers])

  const ResetMarkers = () =>
    setState(s => ({ ...s, markers: s.originalMarkers, tags: [], filters: [] }))

  return (
    <TagsMarkersContext.Provider
      value={{
        state,
        setState,
        ResetMarkers,
        ApplyFilters,
        IsInsideRadius,
        ApplyRadiusFilter
      }}
    >
      {children}
    </TagsMarkersContext.Provider>
  )
}
