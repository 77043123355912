import React, { PureComponent } from 'react'

import base from '../../lib/firebase'

export const ViewsContext = React.createContext([])

export const withViewsContext = WrappedComponent => props => (
  <ViewsContext.Consumer>
    {state => <WrappedComponent {...props} {...state} />}
  </ViewsContext.Consumer>
)

export default class EventsProvider extends PureComponent {
  state = {
    views: {}
  }

  componentDidMount() {
    base.bindToState('views', {
      context: this,
      state: 'views'
    })
  }

  render() {
    return (
      <ViewsContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </ViewsContext.Provider>
    )
  }
}
