import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { withAuthContext } from '../AuthProvider'
import Layout from '../Layout'

const PrivateRoute = ({
  component: Component,
  authorizedUser,
  logout,
  location,
  ...rest
}) => {
  if (!authorizedUser) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: '/'
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  )
}

export default withAuthContext(PrivateRoute)
