import Rebase from 're-base'
import firebase from 'firebase/app'
import 'firebase/database'

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: 'tfm-cloud-16e68',
  appId: '1:1020427503916:web:71e340945cb25dc3580489'
})

const db = firebase.database(app)

export default Rebase.createClass(db)
