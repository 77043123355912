import React from 'react'
import { mark, markerLabel } from './Marker.module.scss'
import { Icon } from './icons/Icon'

const Marker = ({ updatePanel, color = '#403f3f', ...props }) => {
  const { display_name, check_in_status, icon_type } = props || {}

  if (check_in_status === 'On Break') {
    color = '#426267'
  }

  return (
    <div
      className={mark}
      onClick={() => {
        updatePanel()
      }}
    >
      <div className={markerLabel}>
        <div>{display_name || 'No desc'}</div>
      </div>
      <Icon
        name={icon_type}
        style={{ width: '24px', height: '36px' }}
        fill={color}
      />
    </div>
  )
}

export default Marker
