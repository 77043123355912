import queryString from 'query-string'

const resetQuery = ({ props: { history }, ResetMarkers, setQueryParams }) => {
  const location = Object.assign({}, history.location)

  const parsed = queryString.parse(location.search, {
    arrayFormat: 'comma'
  })
  const modified = {
    c: parsed.c,
    z: parsed.z
  }

  location.search = queryString.stringify(modified, {
    arrayFormat: 'comma'
  })
  setQueryParams(location.search)
  history.push(location)
  ResetMarkers()
}

export const getCallback = type => {
  let method = () => {}

  switch (type) {
    case 'resetQuery':
      method = resetQuery
      break
    default:
      break
  }

  return method
}
