import React, { PureComponent } from 'react'
import base from '../../lib/firebase'
import { normalize, schema } from 'normalizr'

const locationMarkSchema = new schema.Entity(
  'locationMarkers',
  {},
  { idAttribute: 'locationid' }
)

export const LocationContext = React.createContext()

export const withLocationsContext = WrappedComponent => props => (
  <LocationContext.Consumer>
    {state => <WrappedComponent {...props} {...state} />}
  </LocationContext.Consumer>
)

export default class LocationProvider extends PureComponent {
  state = {
    locations: []
  }

  componentDidMount() {
    base.listenTo('location-markers', {
      context: this,
      then: data =>
        this.setState({
          locations: normalize(data, [locationMarkSchema])['entities'][
            'locationMarkers'
          ]
        })
    })
  }

  render() {
    const { locations } = this.state
    const arrLocations = !!locations
      ? Object.keys(locations).map(key => locations[key])
      : []

    return (
      <LocationContext.Provider value={{ locations: arrLocations }}>
        {this.props.children}
      </LocationContext.Provider>
    )
  }
}
