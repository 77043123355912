import React, { useContext } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import { ConfirmationDialogContext } from '../ConfirmationDialog'

export const ConfirmationDialog = () => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { state, cleanUp } = useContext(ConfirmationDialogContext)

  const handleEntering = () => {}

  const handleCancel = () => {
    if (!!state.cancelAction) {
      state.cancelAction()
    }
    cleanUp()
  }

  const handleOk = () => {
    if (!!state.confirmAction) {
      state.confirmAction()
    }
    cleanUp()
  }

  return (
    <Dialog
      disableBackdropClick
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="sm"
      open={state.show}
    >
      <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>
      <DialogContent dividers>
        <div>{state.message}</div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {state.cancel}
        </Button>
        <Button onClick={handleOk} color="primary">
          {state.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
