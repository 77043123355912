import React from 'react'

export const compose = (...funcs) =>
  funcs.reduce((a, b) => (...args) => a(b(...args)), arg => arg)

export const composeProviders = (...providers) => {
  const { length } = providers
  if (length === 1) {
    const Provider = providers[0]
    return props => <Provider {...props}>{props.children}</Provider>
  }

  return providers.reduce((ProviderA, ProviderB) => props => (
    <ProviderA>
      <ProviderB>{props.children}</ProviderB>
    </ProviderA>
  ))
}
