import { atom } from 'recoil'

export const appTheme = atom({
  key: 'theme',
  default: localStorage.getItem('theme') || 'light'
})

export const appLanguage = atom({
  key: 'language',
  default: localStorage.getItem('lang') || 'en'
})
