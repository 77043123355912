import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const HeaderContainer = styled('header')`
  border-bottom: 1px solid rgb(235, 237, 240);
  position: relative;
  width: 100%;
  display: flex;
  padding: 0 1.5rem;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  background-image: ${({ $mainBg }) =>
    $mainBg
      ? `linear-gradient(135deg, #fff 0%, #fff 200px, ${$mainBg} 200px, ${$mainBg} 100%)`
      : 'linear-gradient(135deg, #fff 0%, #fff 200px, #47A0DB 200px, #47A0DB 100%)'};
  color: ${({ $textColor }) => ($textColor ? $textColor : '#000')};
`

export const Title = styled.h1`
  color: #fff;
  margin: 0;
  cursor: pointer;
  font-size: 1.5rem;
  text-align: center;
  @media screen and (max-width: 650px) {
    color: #000;
  }
  @media screen and (max-width: 450px) {
    color: #000;
    font-size: 1rem;
  }
`

export const NavBar = styled.nav`
  align-self: stretch;
  display: flex;
  align-items: stretch;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: stretch;
    display: none;

    @media screen and (min-width: 480px) {
      display: flex;
    }

    li {
      display: flex;
      margin-left: 10px;
      align-items: center;
      position: relative;
    }
  }
`

export const NavItem = styled(NavLink)`
  color: inherit;
  display: inline-block;
  padding: 22px 20px;

  &.active,
  &:hover {
    background-color: ${({ $color }) => ($color ? $color : '#47A0DB')};
  }
`

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 5px 0;
  background-color: white;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 99;
`

export const MenuItem = styled.span`
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857;
  color: #333;
  cursor: pointer;
`

export const ProfileBtn = styled('button')`
  color: inherit;
  display: inline-block;
  background: transparent;
  border: none;
  font-size: inherit;
  cursor: pointer;
  height: 100%;
  padding: 0 8px;
  outline: none;

  &.focus {
    background-color: ${({ $open, $color }) => ($open ? $color : 'inherit')};
  }

  &.active,
  &:hover {
    background-color: ${({ $color }) => ($color ? $color : '#47A0DB')};
  }
`
