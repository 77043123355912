import React, { PureComponent } from 'react'
import { withMarkerContext } from '../../components/MarkerProvider'
import Row from './Row'
import styles from './Management.module.scss'

class Management extends PureComponent {
  state = {
    filter: '',
    statusFilter: ''
  }

  render() {
    const { data } = this.props
    if (!data.length) {
      return 'No Data Found'
    }

    return (
      <div className={styles.management}>
        <div className={styles.filterBox}>
          <input
            type="text"
            placeholder="Filter..."
            autoFocus={true}
            value={this.state.filter}
            onChange={({ target }) => this.setState({ filter: target.value })}
          />
          <select
            value={this.state.statusFilter}
            onChange={({ target }) =>
              this.setState({ statusFilter: target.value })
            }
          >
            <option value="">All</option>
            <option value="On Schedule">Active</option>
            <option value="On Break">On Break</option>
          </select>
        </div>
        <ul className={styles.locations}>
          {data
            .filter(item => item.key.includes(this.state.filter))
            .filter(item =>
              this.state.statusFilter
                ? item.check_in_status === this.state.statusFilter
                : true
            )
            .map(item => (
              <li key={item.key}>
                <Row {...item} />
              </li>
            ))}
        </ul>
      </div>
    )
  }
}

const mapData = ({ entities, result }) => ({
  data: result.rawLocations.map(id => entities.markers[id])
})

export default withMarkerContext(mapData)(Management)
