import React from 'react'
import './../Panel.scss'
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import moment from 'moment'
import styled from 'styled-components'

const Value = styled('div')`
  width: 65%;
  color: ${({ $color }) => ($color ? $color : '#000')};
`

const getValue = (value, render = null, props = null) => {
  if (!render) {
    return value
  }

  if (!value && render !== 'link') {
    return null
  }

  let element = null,
    other = {}
  switch (render) {
    case 'link':
      element = (
        <a {...props} target="_blank">
          {props.label}
        </a>
      )
      break
    case 'phone':
      try {
        other.parsed = parsePhoneNumber(value)
        if (other.parsed) {
          if (other.parsed.country === 'US') {
            other.result = formatPhoneNumber(value)
          } else {
            other.code = formatPhoneNumber(value).split(' ')[0]
            other.first = formatPhoneNumber(value)
              .split(' ')[1]
              .substring(0, 3)
            other.second = formatPhoneNumber(value)
              .split(' ')[1]
              .substring(3)
            other.result = `(${other.code}) ${other.first}-${other.second}`
          }
          element = other.result
        }
      } catch (error) {
        console.log('Failed to parse phone')
        console.log(error)
        element = value
      }

      break
    case 'dateTime':
      element = moment(value).format('YYYY-MM-DD HH:mm:ss')
      break
    default:
      break
  }

  return element
}

export const PanelRow = props => {
  const { label, value, color = null, render, props: otherProps = {} } = props

  const rendering = getValue(value, render, otherProps)

  return (
    <div className="row-container">
      <div className="label-container">{label}</div>
      <Value $color={color}>{rendering}</Value>
    </div>
  )
}
