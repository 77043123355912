import React, { PureComponent, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'

import { compose } from '../../../utils'
import { withRoutesContext } from '../../../components/RoutesProvider'
import { withMarkerContext } from '../../../components/MarkerProvider'
import { withLocationsContext } from '../../../components/LocationsProvider'
import {
  TagsMarkersProvider,
  TagsMarkersContext
} from '../../../components/TagsMarkersProvider'
import {
  getQueryParam,
  withQueryParamsContext
} from '../../../components/QueryParamsProvider'
import Marker from '../Marker'
import LocationMarker from '../LocationMarker'
import Panel from '../Panel'
import Loading from './Loading'
import mapConf from './mapConf'
import { memoizeRoutesMap, requestRoute, buildPolyLines } from './util'

let memoizedRoute
try {
  const storage = localStorage.getItem('routesStorage')
  memoizedRoute = memoizeRoutesMap(storage)
} catch (e) {
  memoizedRoute = memoizeRoutesMap()
}

export class CoreMap extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      activeMarker: null,
      loading: false,
      currentCenter: props.defaultCenter
    }

    this.printedPolyLines = []
    this.printedRoutes = []
    this.saveStorage = this.saveStorage.bind(this)
    this.apiLoaded = this.apiLoaded.bind(this)
    this.memoizedRequestRoute = memoizedRoute.memoize(requestRoute)
    this.renderRoutes = this.renderRoutes.bind(this)
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.saveStorage)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.saveStorage)
    this.saveStorage()
  }

  saveStorage() {
    try {
      localStorage.setItem('routesStorage', memoizedRoute.stotage)
    } catch (e) {}
  }

  apiLoaded({ map, maps }) {
    CoreMap.map = map
    CoreMap.googlemaps = maps
    this.props.onMapLoad(this)
  }

  clearRoutes() {
    this.printedRoutes.forEach(display => display.setMap(null))
    this.printedRoutes = []
    this.printedPolyLines.forEach(line => line.setMap(null))
    this.printedPolyLines = []
  }

  renderRoutes(routes) {
    this.clearRoutes()
    const { googlemaps, map } = CoreMap
    if (!googlemaps && !map) {
      console.log('Not loaded')
      return
    }

    const limit = 8
    let delay = 0
    const delayFactor = 1000
    const firstBatch = routes.slice(0, limit)
    const secondBatch = routes.slice(limit)

    firstBatch.forEach(route => this.renderRoute(route))
    secondBatch.forEach(route => {
      const { from, to, waypoints } = route
      if (memoizedRoute.areMemoized(from, to, waypoints)) {
        this.renderRoute(route)
      } else {
        delay += delayFactor
        setTimeout(() => {
          this.renderRoute(route)
        }, delay)
      }
    })

    setTimeout(() => {
      this.setState({ loading: false })
    }, delay)
  }

  renderRoute(route) {
    const { color, from, to, waypoints, polyLines } = route
    const { googlemaps, map } = CoreMap
    if (polyLines && polyLines.length > 0) {
      buildPolyLines(polyLines, route).forEach(line => {
        this.printedPolyLines.push(line)
        line.setMap(map)
      })
    }

    const directionsDisplay = new googlemaps.DirectionsRenderer({
      suppressMarkers: true,
      preserveViewport: true,
      polylineOptions: {
        strokeColor: color || '#84bf7e',
        strokeWeight: 2
      }
    })
    this.printedRoutes.push(directionsDisplay)
    directionsDisplay.setMap(map)

    this.memoizedRequestRoute(from, to, waypoints)
      .then(({ response }) => directionsDisplay.setDirections(response))
      .catch(error => {
        console.dir(route)
        console.error(error)
      })
  }

  render() {
    let { activeMarker, loading, currentCenter } = this.state
    let {
      locations,
      markers,
      defaultCenter,
      defaultZoom,
      tags,
      filters,
      queryParams
    } = this.props

    if (!currentCenter.lat || !currentCenter.lng || !defaultZoom) {
      return null
    }
    const showLocator =
      getQueryParam('showLocator', queryParams, false) === 'true' || false
    const showCenter =
      getQueryParam('showCenter', queryParams, false) === 'true' || false

    return (
      <TagsMarkersProvider
        defaultCenter={defaultCenter}
        center={currentCenter}
        zoom={defaultZoom}
        markers={markers}
        filters={filters}
        tags={tags}
        showLocator={showLocator}
        showCenter={showCenter}
      >
        <TagsMarkersContext.Consumer>
          {({ state, setState, ApplyRadiusFilter, ApplyFilters }) => {
            const onChangeMap = ({ center, zoom }) => {
              this.setState({ currentCenter: center })
              if (state.originalMarkers.length) {
                let filteredMarkers = ApplyFilters(
                  state.filters,
                  state.tags,
                  state.originalMarkers
                )
                filteredMarkers = ApplyRadiusFilter(
                  filteredMarkers,
                  center,
                  zoom
                )

                if (showLocator) {
                  const locatorMarker = {
                    display: [],
                    display_name: 'Locator',
                    icon_type: 'black_marker',
                    key: 'locator',
                    lat: center.lat,
                    lng: center.lng
                  }
                  filteredMarkers.push(locatorMarker)
                }
                if (showCenter) {
                  const centerMarker = {
                    display: [],
                    display_name: 'Center',
                    icon_type: 'black_marker',
                    key: 'center',
                    lat: defaultCenter.lat,
                    lng: defaultCenter.lng
                  }
                  filteredMarkers.push(centerMarker)
                }
                setState(s => ({
                  ...s,
                  markers: filteredMarkers
                }))
              }
            }

            return (
              <Fragment>
                {loading && <Loading />}
                {activeMarker && (
                  <Panel
                    {...activeMarker}
                    closePanel={() => this.setState({ activeMarker: null })}
                  />
                )}
                <GoogleMapReact
                  {...mapConf}
                  defaultCenter={defaultCenter}
                  defaultZoom={defaultZoom}
                  geometryLibrary
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={this.apiLoaded}
                  onChange={onChangeMap}
                >
                  {locations.map(location => (
                    <LocationMarker
                      key={location.locationid}
                      lat={location['latitude']}
                      lng={location['longitude']}
                      {...location}
                    />
                  ))}

                  {state.markers.map(marker => (
                    <Marker
                      key={marker.key}
                      {...marker}
                      updatePanel={() => {
                        if (!marker.display || marker.display.length <= 0) {
                          this.setState({
                            activeMarker: null
                          })
                          return
                        }

                        this.setState({
                          activeMarker: marker
                        })
                      }}
                    />
                  ))}
                </GoogleMapReact>
              </Fragment>
            )
          }}
        </TagsMarkersContext.Consumer>
      </TagsMarkersProvider>
    )
  }
}

const mapData = ({ entities }, { visibleRoutes }) => {
  const { markers } = entities
  if (!markers) {
    return {
      markers: []
    }
  }

  const showAllMarkers =
    !!process.env.REACT_APP_SHOW_ALL_MARKERS &&
    process.env.REACT_APP_SHOW_ALL_MARKERS === 'true'

  let markersFinal = Object.keys(markers).map(key => markers[key])
  if (!showAllMarkers) {
    markersFinal = markersFinal.filter(
      marker =>
        !!marker.lat && !!marker.lng && !!marker.color && !!marker.display
    )
  } else {
    markersFinal = markersFinal.filter(marker => !!marker.lat && !!marker.lng)
  }

  return {
    markers: markersFinal
  }
}

const mapRoutesData = ({ visibleRoutes }) => ({
  visibleRoutes
})

export default compose(
  withRouter,
  withRoutesContext(mapRoutesData),
  withLocationsContext,
  withQueryParamsContext,
  withMarkerContext(mapData)
)(CoreMap)
