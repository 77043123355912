import React, { createContext, useState } from 'react'

export const ConfirmationDialogContext = createContext({
  state: {},
  setState: () => {},
  cleanUp: () => {}
})

export const withConfirmationDialogContext = WrappedComponent => props => (
  <ConfirmationDialogContext.Consumer>
    {state => <WrappedComponent {...props} {...state} />}
  </ConfirmationDialogContext.Consumer>
)

export const ConfirmationDialogProvider = ({ children }) => {
  const [state, setState] = useState({
    show: false,
    message: '',
    confirm: '',
    cancel: '',
    cancelAction: () => {},
    confirmAction: () => {}
  })

  const cleanUp = () =>
    setState({
      show: false,
      message: '',
      confirm: '',
      cancel: '',
      cancelAction: () => {},
      confirmAction: () => {}
    })

  return (
    <ConfirmationDialogContext.Provider value={{ state, setState, cleanUp }}>
      {children}
    </ConfirmationDialogContext.Provider>
  )
}
