import { CoreMap } from './GMap'

export function memoizeRoutesMap(initialStorage = '{}') {
  const storage = JSON.parse(initialStorage) || {}

  return {
    areMemoized(...args) {
      const key = JSON.stringify(args)
      return !!storage[key]
    },
    get stotage() {
      return JSON.stringify(storage)
    },
    memoize: request => (...args) => {
      const key = JSON.stringify(args)

      if (storage[key]) {
        return Promise.resolve(JSON.parse(storage[key]))
      }
      return request(...args).then(res => {
        storage[key] = JSON.stringify(res)
        return res
      })
    }
  }
}

function createLocation(location, googlemaps) {
  if (!location) {
    return
  }
  const { lat, lng, direction } = location
  if (direction) {
    return direction
  }

  const { LatLng } = googlemaps
  if (lat && lng && LatLng) {
    return new LatLng(lat, lng)
  }
  throw new Error('Invalid location object')
}

function parseWaypoints(waypoints, googlemaps) {
  if (!waypoints) {
    return null
  }

  return waypoints
    .filter(location => location)
    .map(location => ({
      location: createLocation(location, googlemaps)
    }))
}

export function requestRoute(from, to, waypoints) {
  const { googlemaps } = CoreMap

  let routeConfig = {
    origin: createLocation(from, googlemaps),
    destination: createLocation(to, googlemaps),
    travelMode: googlemaps.TravelMode.DRIVING,
    waypoints: parseWaypoints(waypoints, googlemaps)
  }

  const { DirectionsService, DirectionsStatus } = googlemaps

  const directionsService = new DirectionsService()
  return new Promise((resolve, reject) => {
    directionsService.route(routeConfig, (response, status) => {
      if (status === DirectionsStatus.OK) {
        return resolve({ response, status })
      } else {
        return reject({ response, status })
      }
    })
  })
}

export function buildPolyLines(polylines, route) {
  const { googlemaps } = CoreMap
  const { color } = route

  const { Polyline } = googlemaps

  return polylines.map(({ from, to }) => {
    const coords = [
      createLocation(from, googlemaps),
      createLocation(to, googlemaps)
    ]
    return new Polyline({
      path: coords,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: 2
    })
  })
}
