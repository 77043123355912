import React, { createContext, useState } from 'react'

export const AlertContext = createContext({
  alertState: {},
  setAlertState: () => {}
})

export const withAlertContext = WrappedComponent => props => (
  <AlertContext.Consumer>
    {state => <WrappedComponent {...props} {...state} />}
  </AlertContext.Consumer>
)

export const AlertProvider = ({ children }) => {
  const [alertState, setAlertState] = useState({
    show: false,
    message: '',
    type: 'info'
  })

  return (
    <AlertContext.Provider value={{ alertState, setAlertState }}>
      {children}
    </AlertContext.Provider>
  )
}
