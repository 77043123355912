import axios from 'axios'

const token = localStorage.getItem('token')

export const API_instance = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: { Authorization: `Bearer ${token}` }
  })

export const RDS_instance = () =>
  axios.create({
    baseURL: process.env.REACT_APP_RDS_API_ENDPOINT,
    headers: { Authorization: `Bearer ${token}` }
  })
