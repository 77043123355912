import React, { createContext, useState } from 'react'
import queryString from 'query-string'

export const getQueryParam = (key, queryParams, isMulti = true) => {
  const parsed = queryString.parse(queryParams, {
    arrayFormat: 'comma'
  })
  let params = parsed[key] || []
  params = typeof params === 'string' ? [params] : params

  const queryObj = {}
  if (isMulti) {
    for (const param of params) {
      const keyXvalue = param.split('_')
      queryObj[keyXvalue[0]] = keyXvalue[1]
    }
  } else {
    return parsed[key]
  }

  return queryObj
}

export const QueryParamsContext = createContext({
  queryParams: '',
  setQueryParams: () => {}
})

export const withQueryParamsContext = WrappedComponent => props => (
  <QueryParamsContext.Consumer>
    {state => <WrappedComponent {...props} {...state} />}
  </QueryParamsContext.Consumer>
)

export const QueryParamsProvider = ({ children }) => {
  const [state, setState] = useState(null)

  return (
    <QueryParamsContext.Provider
      value={{ queryParams: state, setQueryParams: setState }}
    >
      {children}
    </QueryParamsContext.Provider>
  )
}
