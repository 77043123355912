import React, { PureComponent } from 'react'
import { normalize, schema } from 'normalizr'
import firebase from '../../lib/firebase'

const asArray = (obj, processValue = value => value, keyId = 'key') =>
  Object.keys(obj).map(id => ({
    ...processValue(obj[id]),
    [keyId]: id
  }))

const marker = new schema.Entity(
  'markers',
  {},
  {
    idAttribute: 'key',
    mergeStrategy: (rawLocation, metadata) => ({
      ...metadata,
      ...rawLocation
    })
  }
)
const payloadSchema = { markerMetadata: [marker], rawLocations: [marker] }

export const MarkerContext = React.createContext()

export const withMarkerContext = (
  mapData = state => state
) => WrappedComponent => props => (
  <MarkerContext.Consumer>
    {state => <WrappedComponent {...props} {...mapData(state, props)} />}
  </MarkerContext.Consumer>
)

export default class MarkerProvider extends PureComponent {
  state = {
    rawLocations: [],
    markerMetadata: []
  }

  componentDidMount() {
    firebase.listenTo('marker-metadata', {
      context: this,
      then: data => {
        this.setState({
          markerMetadata: asArray(data, value => ({ ...value, checkin: true }))
        })
      }
    })

    firebase.listenTo('raw-locations', {
      context: this,
      then: data => {
        this.setState({
          rawLocations: asArray(data, value => {
            const size = value.length
            return value[size - 1]
          })
        })
      }
    })
  }

  render() {
    const normalizedData = normalize(this.state, payloadSchema)

    return (
      <MarkerContext.Provider value={{ ...normalizedData }}>
        {this.props.children}
      </MarkerContext.Provider>
    )
  }
}
