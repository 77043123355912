import React, { useContext } from 'react'
import { Alert } from '@material-ui/lab'
import { AlertContext } from '../Alert'

export const AlertComponent = () => {
  const { alertState, setAlertState } = useContext(AlertContext)

  const onClose = () =>
    setAlertState({ show: false, message: '', type: 'info' })

  if (!alertState.show) {
    return null
  }

  setTimeout(() => onClose(), 5000)

  return (
    <Alert onClose={onClose} severity={alertState.type}>
      {alertState.message}
    </Alert>
  )
}
