import React, { Component } from 'react'
import deepEqual from 'deep-equal'
import cn from 'classnames'

import { withMarkerContext } from '../../../../components/MarkerProvider'
import styles from './MarkerList.module.scss'
import { ReactComponent as Lock } from './lock.svg'

class MarkerList extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      !deepEqual(nextProps.markers, this.props.markers) ||
      !deepEqual(nextProps.route, this.props.route) ||
      nextProps.filter !== this.props.filter
    )
  }

  render() {
    const { markers, route, filter, onToggle } = this.props

    return (
      <ul className={cn(styles.inner)} style={{ borderColor: route.color }}>
        {markers.map(marker => (
          <li key={marker.id} className={styles.inner}>
            <span
              className={cn(styles.field, {
                [styles.field_filter]:
                  filter && marker.eventkd.toLowerCase().includes(filter)
              })}
            >
              {marker.eventkd}
              <span className={styles.indicator} onClick={onToggle}>
                <Lock />
              </span>
            </span>
          </li>
        ))}
      </ul>
    )
  }
}

const mapData = ({ entities: { markers } }, { route, filter }) => {
  if (markers) {
    return {
      markers: Object.keys(markers)
        .map(id => markers[id])
        .filter(marker => marker.checkin && marker.routeid === route.routeid)
        .map(marker => ({
          ...marker,
          lat: null,
          lng: null,
          power: null,
          time: null
        }))
        .sort(a => {
          if (!filter) return 0

          if (a.eventkd.toLowerCase().includes(filter)) {
            return -1
          }

          return 1
        })
    }
  }

  return { markers: [] }
}

export default withMarkerContext(mapData)(MarkerList)
