import React, { Component } from 'react'
import * as API from '../../services/api'

export const AuthContext = React.createContext()

export function withAuthContext(WrappedComponent) {
  return props => (
    <AuthContext.Consumer>
      {state => <WrappedComponent {...props} {...state} />}
    </AuthContext.Consumer>
  )
}

export default class AuthProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      authorizedUser: null,
      loading: false,
      error: null
    }

    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
  }

  async componentWillMount() {
    // FIXME: this isn't working to validate the token
    try {
      const token = localStorage.getItem('token')
      if (token) {
        this.setState({ loading: true })
        const authorizedUser = await API.validate(token)
        this.setState({
          authorizedUser: {
            ...authorizedUser,
            firstName: authorizedUser.first_name,
            lastName: authorizedUser.last_name
          },
          loading: false
        })
      }
    } catch (err) {
      console.dir(err)
      this.setState({ loading: false })
    }
  }

  async login(payload, { setSubmitting }) {
    this.setState({ error: null })
    try {
      const authorizedUser = await API.authenticate(payload)
      localStorage.setItem('token', authorizedUser.token)

      this.setState({ authorizedUser })
      setSubmitting(false)
    } catch ({ response }) {
      console.dir(response)
      if (response && response.data && response.data.message) {
        this.setState({ error: response.data.message })
      } else {
        this.setState({ error: 'Oops something wen`t wrong' })
      }
      setSubmitting(false)
    }
  }

  logout() {
    this.setState({ authorizedUser: null })
    localStorage.removeItem('token')
  }

  render() {
    if (this.state.loading) {
      return <span>Loading</span>
    }

    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          login: this.login,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
