import React, { Component } from 'react'
import deepEqual from 'deep-equal'
import queryString from 'query-string'

import { withViewsContext } from '../../components/ViewsProvider'
import { withRoutesContext } from '../../components/RoutesProvider'
import { withQueryParamsContext } from '../../components/QueryParamsProvider'
import ExtraPanels from './SideMenu'
import GMap, { CoreMap } from './GMap'
import styles from './Tracker.module.scss'
import { compose } from '../../utils'
import { ConfirmationDialog } from '../../components/ConfirmationDialog'
import { AlertComponent } from '../../components/Alert'

const HU_MAP = {
  defaultCenter: {
    lat: parseFloat(process.env.REACT_APP_DEFAULT_CENTER_LAT) || 29.680699,
    lng: parseFloat(process.env.REACT_APP_DEFAULT_CENTER_LNG) || -95.428912
  },
  defaultZoom: parseFloat(process.env.REACT_APP_DEFAULT_CENTER_ZOOM) || 10
}
console.log(HU_MAP)
const DEFAULT_MAP = HU_MAP

class Tracker extends Component {
  state = { ...DEFAULT_MAP, loading: false, tags: [], filters: [] }

  onMapLoad = ref => {
    const { routes } = this.props
    this.mapRef = ref

    if (routes.length) {
      this.mapRef.renderRoutes(routes)
    }
  }

  static getDerivedStateFromProps(props) {
    const { location, match, views } = props
    const { viewSlug } = match.params
    let state = DEFAULT_MAP

    const { tags, filters, c, z } = queryString.parse(location.search, {
      arrayFormat: 'comma'
    })

    if (viewSlug && !c) {
      let customView = null
      for (let [k, v] of Object.entries(views)) {
        if (v.slug.toLowerCase() === viewSlug.toLowerCase()) {
          customView = views[k]
        }
      }

      if (customView) {
        state = {
          defaultCenter: customView.center,
          defaultZoom: customView.zoom,
          loading: false,
          tags: [],
          filters: []
        }
      } else {
        state = { ...state, loading: true }
      }
    }

    if (!!c) {
      state = {
        ...state,
        defaultCenter: {
          lat: +c[0],
          lng: +c[1]
        },
        defaultZoom: +z,
        loading: false,
        tags: [],
        filters: []
      }
    }

    if (!location.search || (!tags && !filters)) {
      return state
    }

    if (!!tags) {
      state = { ...state, tags: typeof tags === 'string' ? [tags] : tags }
    }

    if (!!filters) {
      state = {
        ...state,
        filters: typeof filters === 'string' ? [filters] : filters
      }
    }

    return state
  }

  componentWillUnmount() {
    // FIXME: this was here but there was no implementation for this default filters
    // RoutesProvider._defaultFilters()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqual(nextProps.routes, this.props.routes) ||
      this.state.loading !== nextState.loading
    )
  }

  render() {
    const { loading, customView } = this.state
    const { routes = [], match, location, setQueryParams } = this.props
    const { viewSlug } = match.params

    setQueryParams(location.search)

    if (loading) {
      return null
    }

    if (this.mapRef && CoreMap.map && CoreMap.googlemaps) {
      this.mapRef.renderRoutes(routes)
    }

    return (
      <>
        <AlertComponent />
        <ConfirmationDialog />
        <section className={styles.container}>
          {viewSlug && customView ? (
            <ExtraPanels customView={customView} />
          ) : (
            <ExtraPanels />
          )}
          <GMap {...this.state} onMapLoad={this.onMapLoad} />
        </section>
      </>
    )
  }
}

const mapData = ({ routes, visibleRoutes }) => {
  return {
    routes: Object.keys(routes)
      .filter(key =>
        visibleRoutes.length ? visibleRoutes.indexOf(key) > -1 : true
      )
      .map(key => routes[key])
  }
}
export default compose(
  withViewsContext,
  withQueryParamsContext,
  withRoutesContext(mapData)
)(Tracker)
