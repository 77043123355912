import React, { useEffect, useRef, useState } from 'react'
import Bus from './bus'

export const Icon = ({ name = 'bus', ...rest }) => {
  const ImportedIconRef = useRef(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const importIcon = async () => {
      try {
        const imported = await import(`./${name.toLowerCase()}`)
        ImportedIconRef.current = imported.default
      } catch (err) {
        ImportedIconRef.current = Bus
      } finally {
        setLoading(false)
      }
    }
    importIcon()
  }, [name])

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef
    return <ImportedIcon {...rest} />
  }

  return null
}
